import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../components/layout";
import SEO from "../components/seo";
import * as CONST from "../components/const";

const bg_h = "../images/top/bg.png";
// const bg_v = "../images/top/bg_v.png";
const kv = "../images/top/kv.jpg";

function IndexPage() {
  const STATIC_IMAGE_PLACEHOLDER = "none"; // blurred tracedSVG none

  return (
    <Layout>
      <SEO
        keywords={[`IT`, `ネットワーク`, `開発`, `東京`]}
        title="Home"
      />

      <section className={CONST.SECTION_P_AREA_CLASS} >
        <StaticImage quality="100" layout="fullWidth" alt="" placeholder={STATIC_IMAGE_PLACEHOLDER} className={CONST.TOP_BACK_GROUND_PATTERN_CLASS} src={bg_h} />
        {/* <StaticImage quality="100" layout="fullWidth" alt="" placeholder={STATIC_IMAGE_PLACEHOLDER} className={CONST.PC_HIDDEN_CLASS + CONST.TOP_BACK_GROUND_PATTERN_CLASS} src={bg_v} /> */}
        <div className="w-full sm:my-10 2xl:mb-20 3xl:mb-28 leading-11 xl:leading-9 z-10 font-normal xl:text-base">
          <p className="text-md xl:text-base tracking-wider">HORIZON × SYSTEM</p>
          <p className="text-2xl xs:text-3xl sm:text-6xl tracking-wider md:font-light my-5 sm:mb-20 xl:mt-8 xl:mb-16">挑戦を、やめない</p>
          <p>お客様にとって最高のパートナーであるために</p>
          <p className="pb-4">時代の先端を取り込み</p>
          <p>日々変化するDX時代に柔軟な提案を</p>
        </div>
      </section>

      <div className="bg-gradient-to-t from-gray-light xl:-mt-16 2xl:-mt-28 3xl:-mt-20">
        <section className={CONST.SECTION_AREA_REVERSE_CLASS}>
          <div className={CONST.TOP_KEY_VISUAL_OFFSET_MESSAGE_AREA_LEFT_CLASS} >
            <div className="sm:px-5 py-4 xl:py-0">
              <h1 className={CONST.H1_CLASS}>MISSION</h1>
              <div className="text-base sm:text-lg xl:text-base tracking-wider md:tracking-widest sm:leading-11 xl:leading-9">
                <p>私たちホライゾンは、お客様のビジネスに合わせ、システム企画から運用保守まで一気通貫でのサービスをご提供いたします。</p>
                <p>私たちホライゾンは、お客様をＩＴの脅威から守るため、セキュリティコンサルティングからお客様のセキュリティ人材育成まで一気通貫でのサービスをご提供いたします。</p>
                <p>私たちホライゾンは、お客様を原点としたサービスのご提供を実践してまいります。</p>
              </div>
            </div>
          </div>
          <div className={CONST.TOP_KEY_VISUAL_RIGHT_IMG_AREA_CLASS}>
            <StaticImage quality="100" layout="fullWidth" alt="" placeholder={STATIC_IMAGE_PLACEHOLDER} className={CONST.KEY_VISUAL_VARIABLE_IMG_CLASS} src={kv} />
          </div>
        </section>
      </div>
    </Layout >
  );
}

export default IndexPage;

